.dataTables_wrapper .dataTable th.sorting_asc,
.dataTables_wrapper .dataTable td.sorting_asc,
.dataTables_wrapper .dataTable th.sorting_desc,
.dataTables_wrapper .dataTable td.sorting_desc {
    color: #3699ff !important;
}

.dataTables_wrapper .dataTable th.sorting_asc:before,
.dataTables_wrapper .dataTable td.sorting_asc:before {
    color: #3699ff !important;
}

.dataTables_wrapper .dataTable th.sorting_desc:after,
.dataTables_wrapper .dataTable td.sorting_desc:after {
    color: #3699ff !important;
}

.dataTables_wrapper .dataTable tbody td {
    vertical-align: middle !important;
}

table.table-bordered.dataTable {
    border-right-width: 1px !important;
}

table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
    border-right-width: 0 !important;
}

table tbody tr td.dt-body-center {
    text-align: center !important;
}

// table.dataTable>thead>tr>th:not(.sorting_disabled):first-child, table.dataTable>thead>tr>td:not(.sorting_disabled):first-child {
//     border: ;
// }
// #ffe2e5
// .dataTables_wrapper .dataTable th.sorting_desc:before, .dataTables_wrapper .dataTable th.sorting_desc:after, .dataTables_wrapper .dataTable th.orting_asc_disabled:before, .dataTables_wrapper .dataTable th.orting_asc_disabled:after, .dataTables_wrapper .dataTable th.orting_desc_disabled:before, .dataTables_wrapper .dataTable th.orting_desc_disabled:after, .dataTables_wrapper .dataTable th.sorting_asc:before, .dataTables_wrapper .dataTable th.sorting_asc:after, .dataTables_wrapper .dataTable th.sorting:before, .dataTables_wrapper .dataTable th.sorting:after, .dataTables_wrapper .dataTable td.sorting_desc:before, .dataTables_wrapper .dataTable td.sorting_desc:after, .dataTables_wrapper .dataTable td.orting_asc_disabled:before, .dataTables_wrapper .dataTable td.orting_asc_disabled:after, .dataTables_wrapper .dataTable td.orting_desc_disabled:before, .dataTables_wrapper .dataTable td.orting_desc_disabled:after, .dataTables_wrapper .dataTable td.sorting_asc:before, .dataTables_wrapper .dataTable td.sorting_asc:after, .dataTables_wrapper .dataTable td.sorting:before, .dataTables_wrapper .dataTable td.sorting:after {
//     color: aliceblue !important;
// }
