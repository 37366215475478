#loading-section {
    .bounce {
        position: absolute;
        top: 50%;
        left: 0;
        text-align: center;
    }

    .bounce > div {
        width: 22px;
        height: 22px;
        background-color: #fff;
        margin: 5px;
        border-radius: 100%;
        display: inline-block;
        animation: sk-bouncedelay 1.25s infinite ease-in-out both;
    }

    .bounce .bounce1 {
        animation-delay: -0.32s;
    }

    .bounce .bounce2 {
        animation-delay: -0.16s;
    }

    @keyframes sk-bouncedelay {
        0%,
        80%,
        100% {
            transform: scale(0);
        }
        40% {
            transform: scale(1);
        }
    }

    .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9999;
        display: flex;
    }

    .overlay h1 {
        position: absolute;
        display: flex;
        font-weight: 600;
        font-size: 20px;
        color: white;
        top: 50%;
        text-transform: uppercase;
        align-items: center;
        justify-content: center;
        margin-top: 3rem !important;
    }
}
