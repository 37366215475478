.DatePicker {
    z-index: 96 !important;
}

.Calendar__monthSelectorItem.-active .Calendar__monthSelectorItemText,
.Calendar__yearSelectorItem.-active .Calendar__yearSelectorText {
    background-color: var(--primary) !important;
}

.Calendar__day.-selected,
.Calendar__day.-selectedStart,
.Calendar__day.-selectedEnd {
    background: var(--primary) !important;
}

.Calendar__day.-selectedBetween {
    background: #e1f0ff !important;
    color: var(--primary) !important;
}
